<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Активность"
              label-for="is_public"
            >
              <b-form-checkbox
                id="is_public"
                v-model="item.is_public"
                :state="get(validation, 'is_public[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.is_public">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'is_public[0]')"
                class="text-danger"
              >
                {{ get(validation, 'is_public[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Slug"
              label-for="slug"
              description="Если не заполнен, генерируется автоматически на основе названия"
            >
              <b-form-input
                id="name"
                v-model.trim="item.slug"
                placeholder="Введите slug"
                :state="get(validation, 'slug[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'slug[0]')"
                class="text-danger"
              >
                {{ get(validation, 'slug[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <image-uploader
              v-model="item.images"
              :company-id="$store.getters['workingMode/company_id']"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BFormTextarea, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import get from 'lodash/get'
import { getCurrentInstance } from 'vue'
import { createPortfolio } from '@/services/main-api/sites/portfolio'
import useCrudCreate from '@/composables/useCrudCreate'
import ImageUploader from '@/components/ImageUploader.vue'
import { editFile } from '@/services/main-api/medialibrary/files'

export default {
  name: 'CreateWarehouseView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    ImageUploader,
  },
  props: {
    siteId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props, { root }) {
    const instance = getCurrentInstance().proxy

    const updateImage = async file => editFile({
      id: file.id,
      model: 'portfolio',
      model_id: instance.item.id,
      model_field: 'images',
      title: file.title ? file.title : '',
      model_sort_order: instance.item.images.findIndex(i => i.id === file.id) + 1,
    })

    const updateImages = async () => {
      if (instance.item.images.length) {
        try {
          const promises = instance.item.images.map(updateImage)
          await Promise.all(promises)
        } catch (e) {
          console.log(e)
        }
      }
    }

    const createEntity = async () => {
      const res = await createPortfolio({
        site_id: props.siteId,
        name: instance.item.name,
        is_public: instance.item.is_public,
        slug: instance.item.slug,
        description: instance.item.description,
      })

      instance.item.id = res.data.data.id

      await updateImages()
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        is_public: false,
        name: '',
        slug: '',
        description: '',
        images: [],
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      createEntity,
      getTextAfterCreation: () => 'Портфолио было создано.',
      runAfterCreation: async () => {
        await instance.$router.push({ name: 'portfolios', params: { siteId: props.siteId } })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
